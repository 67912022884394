import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./Views/AboutUs";
import Careers from "./Views/Careers";
import Contact from "./Views/Contact";
import Home from "./Views/Home";
import Search from "./Views/Search";
import Services from "./Views/Services";
import CookiePolicy from "./Views/Cookies/CookiePolicy";
import PrivacyPolicy from "./Views/PrivacyPolicy/PrivacyPolicy";
import IntegrityPolicy from "./Views/IntegrityPolicy";
import Work from "./Views/Work";
import CloudiumService from "./Views/CloudiumService"
import NotFound from "./Views/NotFound"
import ThankYou from "./Views/ThankYou";

import ReactGA from "react-ga4";
import { Fragment } from "react";
import Layout from "./Views/Layout";
import { availableWorksData } from "./data/availableWorksData";
import { servicesData } from "./data/servicesData";

function App() {
  const env = process.env.REACT_APP_ENVIRONMENT;

  if(env === "production")
  {
    const TRACKING_ID = "G-XEWGJBNTNG";
    ReactGA.initialize(TRACKING_ID);
    
    const script = document.createElement('script');
    script.src = 
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3602072,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    script.async = true;

    document.head.appendChild(script);
  }

  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Career" element={<Careers />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/CookiePolicy" element={<CookiePolicy />} />
            <Route path="/IntegrityPolicy" element={<IntegrityPolicy />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Search" element={<Search />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/ThankYou" element={<ThankYou />} />
            <Route path="/" element={<Home />} />
            {
              servicesData.map((cloudiumservice) => {
                return(
                  cloudiumservice.page 
                  ?
                    <Route path={`/Services/${cloudiumservice.page}`} element={<CloudiumService {...cloudiumservice} />} key={cloudiumservice.id.toString()} />
                  :
                    <Route path="/" element={<Home />} />
                  );
              })
            }
            {
              availableWorksData.map((work) => {
                return(<Route path={`/Career/${work.page}`} element={<Work {...work} />} key={work.id.toString()}  />);
              })
            }
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
