import React, { Fragment } from "react";

const IntegrityPolicy = () => {
  return (
    <Fragment>
      <p>Integrity Policy</p>
    </Fragment>
  );
};

export default IntegrityPolicy;
