import React from "react";
import logo from "../../../assets/images/logos/Cloudium 1-143x39.png";

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="logo">
          <img src={logo} className="logo" alt="logo" />
        </div>

        <div className="socialicon">
          <a href="https://www.facebook.com/cloudiumab" className="">
            <i className={`socialicon fab fa-facebook-f`}></i>
          </a>
          <a
            href="https://x.com/cloudiumab"
            className=""
          >
            <i className={`socialicon fa-brands fa-x-twitter`}></i>
          </a>
          <a
            href="https://www.instagram.com/cloudiumab"
            className=""
          >
            <i className={`socialicon fab fa-instagram`}></i>
          </a>
          <a
            href="https://www.linkedin.com/company/cloudium-ab/about/"
            className=""
          >
            <i className={`socialicon fab fa-linkedin`}></i>
          </a>
          <a
            href="https://github.com/Cloudium-AB"
            className=""
          >
            <i className={`socialicon fab fa-github`}></i>
          </a>
        </div>

        <div className="line">
          <hr/>
        </div>
        
        <div className="address">
          <p>Org. nummer: 559437-0313</p>
          <p>
            Cloudium AB <br />
            Kungsgatan 8 <br />
            111 43 Stockholm <br />
            Sweden
          </p>
        </div>

        <div className="links">
          <h6>Länkar</h6>
          <p>
            <a href="/services" className="">
              Våra tjänster
            </a>
          </p>
          <p>
            <a href="/contact" className="">
              Kontakta oss
            </a>
          </p>
          <p>
            <a href="/aboutus" className="">
              Om Cloudium
            </a>
          </p>
          <p>
            <a href="/career" className="">
              Karriär
            </a>
          </p>
        </div>

        <div className="contact">
          <h6>Kontakt</h6>
          <p>
            <a href="mailto:info@cloudium.se" >
              <i className="fas fa-envelope"></i>
              &nbsp;info@cloudium.se
            </a>   
          </p>
          <p>
            <i className="fas fa-phone"></i>
            &nbsp;+46 70 169 40 96
          </p>
          <p>
            <i className="fas fa-phone"></i>
            &nbsp;+46 70 169 40 31
          </p>
        </div>

        <div className="cloudiumcookisettings">
          <button onClick={() => window.displayPreferenceModal()}>Cookie Inställningar</button>
        </div>
        
        <div className="cloudiumcookiepolicy">
          <p>
            <a href="/CookiePolicy" className="">
              Cookie policy
            </a>
          </p>
        </div>

        <div className="cloudiumprivacypolicy">
          <p>
            <a href="/PrivacyPolicy" className="">
              Privacy policy
            </a>
          </p>
        </div>

        <div className="cloudiumcopyright">
          <p>
          © 2024 Copyright&nbsp;
          <a
            className=""
            href="https://cloudium.se/"
          >
            Cloudium AB
          </a>
        </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
