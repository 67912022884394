export const careerData = [
  {
    id: "0",
    image: require("../assets/images/cloudium/group/GroupAtHQ.jpg"),
    imagTitle: "Ta din karriär till nya höjder - bli en del av vårt passionerade team!",
    title: "Karriär",
    text: (
      <div>
        <h4>
          Ta din karriär till nya höjder - bli en del av vårt passionerade team!
        </h4>
        <p>
          Vi är stolta över att vara ett företag med enastående erfarenhet och
          expertis inom IT-branschen, med över 35 års samlad kunskap. Under det
          senaste decenniet har vi specialiserat oss på infrastruktur, SQL
          Server och Azure, och vi är nu en pålitlig partner som vägleder våra
          kunder genom den komplexa världen av IT-infrastruktur och
          molnteknologi.
        </p>
        <p>
          Som en del av vårt team kommer du att ha möjlighet att arbeta sida vid
          sida med erfarna industriledare. Du kommer att vara
          omgiven av en djup förståelse för de utmaningar och möjligheter som
          molnövergångar medför, och få chansen att utvecklas i ett företag som
          är en pionjär inom allting cloud.
        </p>
        <p>
          Vi tror på att erbjuda våra anställda de bästa förutsättningarna för
          framgång. Därför investerar vi i din utveckling och ger dig verktygen
          att blomstra inom ditt område. Tillsammans skapar vi skräddarsydda
          lösningar för våra kunder och levererar resultat som överträffar
          förväntningarna.
        </p>
        <p>
          Din passion för teknik och ditt driv att utvecklas är precis vad vi
          söker. Tillsammans kommer vi att ta våra kunders verksamheter till nya
          höjder genom en effektiv, säker och skalbar IT-infrastruktur i molnet.
        </p>
        <p>
          Bli en del av vårt engagerade team och ta dina karriärsmål till nästa
          nivå. Skicka in din ansökan idag och låt oss tillsammans forma en
          framtid där innovation och framgång går hand i hand. Välkommen till
          Cloudium där din potential blir verklighet!
        </p>
      </div>
    ),
  },
];