import React, { Fragment } from "react";

var termly = require("./Termly.htm");

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <div className="privacy-policy">
        <div className="wrapper">
          <iframe src={termly} className="responsive-iframe" title="Privacy Policy"></iframe>
        </div>
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
