import React, { Fragment } from "react";

var termly = require("./Termly.htm");

const CookiePolicy = () => {
  return (
    <Fragment>
      <div className="cookie-policy">
        <div className="wrapper">
          <iframe src={termly} className="responsive-iframe" title="cookie policy"></iframe>
        </div>
      </div>
    </Fragment>
  );
};

export default CookiePolicy;
  